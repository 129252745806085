import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../style/common.scss";

const privacypolicy = () => (
  <Layout>
    <SEO title="termsofuse" />
    <div className="about-container">
      <h1>プライバシーポリシー</h1>
      <p>
        DevpediaCode（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
      </p>
      <h3>第1条（個人情報）</h3>　
      <p>
        「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
      </p>
      <h3>第2条（個人情報の収集方法）</h3>
      <p>
        当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，所属企業,　役職などの個人情報をお尋ねすることがあります。また，ユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。
      </p>
      <h3>第3条（個人情報を収集・利用する目的）</h3>
      <p>当社が個人情報を収集・利用する目的は，以下のとおりです。</p>
      <ol>
        <li>当社サービスの提供・運営のため</li>
        <li>
          ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
        </li>
        <li>
          ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため
        </li>
        <li>メンテナンス，重要なお知らせなど必要に応じたご連絡のため</li>
        <li>
          商品、サービス、マーケティング活動などに関するアンケート調査のお願い、連絡、回答
        </li>
        <li>資料請求に対する対応</li>
        <li>上記の利用目的に付随する目的</li>
      </ol>
      <h3>第4条（利用目的の変更）</h3>
      <ol>
        <li>
          当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
        </li>
        <li>
          利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
        </li>
      </ol>
      <h3>第5条（個人情報の第三者提供）</h3>
      <ol>
        <li>提供について予めユーザーの同意を得た場合</li>
        <li>
          当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
        </li>
        <li>
          個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合
        </li>
      </ol>
      <h3>第6条 (個人情報の開示)</h3>
      <ol>
        <li>
          当社は，本人から個人情報の開示を求められたときは，ユーザーご本人からのご請求であることを確認の上で，本人に対し，遅滞なくこれを開示します。
        </li>
        <li>
          但し，個人情報保護法その他の法令により，当社が開示の義務を負わない場合は，この限りではありません。なお，個人情報の開示につきましては，手数料（1件あたり1,000円）を頂戴しておりますので，あらかじめ了承ください。
        </li>
      </ol>
      <h3>第7条（個人情報の訂正および削除）</h3>
      <ol>
        <li>
          ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
        </li>
        <li>
          当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
        </li>
        <li>
          当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
        </li>
      </ol>
      <h3>第8条（個人情報の利用停止等）</h3>
      <ol>
        <li>
          当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
        </li>
        <li>
          前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
        </li>
        <li>
          当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
        </li>
      </ol>
      <h3>第9条 (プライバシーポリシーの変更手続)</h3>
      <ol>
        <li>
          本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
        </li>
        <li>
          当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
        </li>
      </ol>
      <h3>第10条 (お問い合わせ窓口)</h3>
      本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
      <br />
      <Link to={"/contact"}>お問い合わせ</Link>
      <br />
      <br />
      当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
      <br />
    </div>
  </Layout>
);

export default privacypolicy;
